<template>
  <div>
    <item-list-view
      :api="api"
      :headers="headers"
      items-key="items"
      id-key="id"
      title="Практики"
      :allowed-search="false"
      :allowed-create="true"
      :allowed-remove="true"
      :update-route="(item) => ({ name: 'ClinicPractice', params: { id: item.id, clinicId: clinic.id } })"
      :create-route="{ name: 'ClinicPractice', params: { id: 'new', clinicId: clinic.id } }"
      remove-title="Удаление практики"
      remove-message="Вы действительно хотите удалить практику '<%= item.name %>'?"
      :request-params="{ organizationId: clinic.id }"
      disable-pagination
      draggable
      @[ON_REORDER]="onReorder"
    >
      <template #pretable>
        <v-card class="mb-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="mr-4">Тип сортировки:</div>
              <div>
                <div class="d-flex">
                  <clinic-practices-sorting-select
                    v-model="clinic.practiceSortingType"
                    outlined
                    dense
                    light
                    hide-details
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <div class="mb-4 mt-8" v-if="sorting === SORTING_TYPE_MANUAL">
          Если сортировка не применилась, попробуйте сбросить
          <router-link target="_blank" to="/cache">общий кэш</router-link>
        </div>

      </template>
      <template v-slot:item.draggable>
        <v-btn icon small>
          <v-icon>mdi-drag</v-icon>
        </v-btn>
      </template>
    </item-list-view>
  </div>
</template>

<script>
import ItemListView, { ON_REORDER } from '@/components/ItemListView'
import api, { SORTING_TYPE_MANUAL } from '@/services/clinic-practices'
import ClinicPracticesSortingSelect from '@/components/ClinicPractice/ClinicPracticesSortingSelect.vue'

export default {
  components: {
    ItemListView,
    ClinicPracticesSortingSelect
  },
  props: {
    sorting: {
      type: String,
      default: SORTING_TYPE_MANUAL
    },
    clinic: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      SORTING_TYPE_MANUAL,
      ON_REORDER,
      api,
    }
  },
  computed: {
    headers () {
      const canDrag = this.clinic.practiceSortingType === SORTING_TYPE_MANUAL

      return [
        { text: '', value: 'draggable', hidden: !canDrag },
        { text: 'Название', value: 'name' },
        { text: '', value: 'action' }
      ].filter(({ hidden }) => !hidden)
    }
  },
  methods: {
    onReorder (items) {
      return api.reorder(items)
    }
  }
}
</script>
