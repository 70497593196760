<template>
  <v-row>
    <v-col cols="4">
      <v-select
        v-model="view"
        :items="options"
        item-text="text"
        item-value="value"
        label="Вид карточек"
        outlined
      />
    </v-col>
  </v-row>

</template>

<script>
import { PRACTICES_VIEWS, PRACTICES_VIEW_CARD } from '@/services/clinics'
import { createEnumProp } from '@/utils/props'

export default {
  props: {
    value: createEnumProp({
      values: PRACTICES_VIEWS,
      default: PRACTICES_VIEW_CARD
    })
  },
  data () {
    return {
      options: Object
        .values(PRACTICES_VIEWS)
        .map((value) => ({ text: value, value }))
    }
  },
  computed: {
    view: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
